@font-face {
  font-family: "EFCircularWeb-Bold";
  src: url("../../public/font/EFCircularWeb-Bold.woff");
}

@font-face {
  font-family: "EFCircularWeb-Light";
  src: url("../../public/font/EFCircularWeb-Light.woff");
}

@font-face {
  font-family: "EFCircularWeb-Book";
  src: url("../../public/font/EFCircularWeb-Book.woff");
}

html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #fffefe;
}

.option {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  font-family: "EFCircularWeb-Book";
  font-weight: 450;
  font-size: 16px;
  line-height: 22.4px;
}

.option:last-child {
  border-bottom: none;
}

.neuroColor {
  font-family: "EFCircularWeb-Book";
  font-weight: 550;
  font-size: 16px;
  line-height: 20.8px;
  margin-bottom: 25px;
}

.questions {
  font-family: "EFCircularWeb-Book";
  font-weight: 700;
  font-size: 24px;
  line-height: 31.2px;
}

button:hover {
  background-color: #dc0079 !important;
}
