@font-face {
    font-family: 'EFCircularWeb-Bold';
    src: url('../../public/font/EFCircularWeb-Bold.woff');
  }
  
  @font-face {
    font-family: 'EFCircularWeb-Light';
    src: url('../../public/font/EFCircularWeb-Light.woff');
  }
  
  @font-face {
    font-family: 'EFCircularWeb-Book';
    src: url('../../public/font/EFCircularWeb-Book.woff');
  }

  .alert-body{
    margin: 0;
    background-color: #B5BCFF;
  }

  .alert-text-box {
    font-family: 'EFCircularWeb-Book';
    font-size: 24px;
    font-weight: 700;
    line-height: 31.2px;
  }

  .okay-btn:hover {
    background-color: transparent !important;
  }